<template>
  <h6 class="font-weight-bold text-black">Operating system</h6>

  <div class="form-check"
       v-for="platform in platforms"
       :key="'platform_'+platform.targeting_value">
    <input class="form-check-input"
           type="checkbox"
           :value="platform.targeting_value"
           v-model="form.devices"
           :id="'platform_'+platform.targeting_value">
    <label class="form-check-label"
           :for="'platform_'+platform.targeting_value">
      {{ platform.name }}
    </label>
  </div>
</template>

<script>
export default {
  props: ['formData', 'errors', 'platforms'],
  data() {
    return {
      form: {},
      products: []
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  }
}
</script>