<template>
  <page-title-component title="Google Campaigns"></page-title-component>

  <div class="row">
    <div class="col-3 mb-3">
      <select class="form-select"
              ref="adAccount"
              @change="changeAccount($event.target.value)">
        <option value="">Select Ad Account</option>

        <option :value="account.id"
                v-for="account in accounts"
                :key="'ac_'+account.id">{{ account.name }}</option>
      </select>
    </div>
    <hr>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="text-center" v-if="loading">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div class="table-responsive" v-else>
        <table class="table mb-0 table-centered">
          <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Spend</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="campaign in campaigns" :key="'campaign_'+campaign.id">
            <td>{{ campaign.id }}</td>
            <td>{{ campaign.name }}</td>
            <td>${{ campaign.spend.toFixed(2) }}</td>
          </tr>
          </tbody>
        </table><!--end /table-->
      </div>
    </div>
  </div>
</template>
<script>
import PageTitleComponent from "@/components/PageTitleComponent";
import {mapGetters} from "vuex";

export default {
  components: {PageTitleComponent},
  data() {
    return {
      accounts: [],
      campaigns: [],
      queryParams: {
        id: '',
      }
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings'
    })
  },
  created() {
    this.loadGoogleAdAccounts();
    // this.loadCampaigns();
  },
  methods: {
    loadGoogleAdAccounts() {
      this.axios.get('/ad-accounts/google')
        .then(response => {
          this.accounts = response.data.data;

          setTimeout(() => {
            if (this.settings.google_profile_id) {
              this.$refs.adAccount.value = this.settings.google_profile_id;
              this.queryParams.id = this.settings.google_profile_id;
              this.loadCampaigns()
            }
          },500);
        })
    },
    changeAccount(profile) {
      this.campaigns = [];
      this.queryParams.id = profile;

      this.$store.dispatch('settings/saveSettings', {
        google_profile_id: profile
      })

      if (profile) {
        this.loadCampaigns();
      }
    },
    loadCampaigns() {
      this.loading = true;

      this.axios.get('/google/campaigns', {
        params: this.queryParams
      }).then(response => {
        this.campaigns = response.data.data;
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>