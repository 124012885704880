<template>
  <div class="modal fade"
       id="x-interest-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-google-campaign-detailsLabel">Interest</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="color: black">
          <div class="row">
            <div class="col-8" style="height: 500px; overflow: scroll">
              <ul class="main-list">
                <li class="main-list-li"
                    v-for="(item, i) in interests"
                    :key="'interset_'+i">
                  <span class="main-list-text" @click="loadSubCategories(item)">
                    <i class="fa-solid fa-chevron-down" style="width: 20px" v-if="item.expanded"></i>
                    <i class="fa-solid fa-chevron-right" style="width: 20px"  v-else></i> {{ item.name }}
                  </span>
                  <div v-if="item.loading"
                       style="display: block; text-align: center; padding: 10px">
                    <div class="spinner-border" role="status"></div>
                  </div>

                  <ul class="sub-list" v-if="item.expanded && item.sub_categories">
                    <li class="sub-list-li"
                        @mouseenter="currentInfo = {item, subItem}"
                        @mouseleave="currentInfo = null"
                        v-for="(subItem, j) in item.sub_categories"
                        :key="'sub_item_'+i+'_'+j">
                      <span>{{item.name }} - {{ subItem.text }}</span>
                      <span style="float: right">
                    <input type="checkbox"
                           :value="subItem.id"
                           @click.stop
                           v-model="form.audience.targeting_criteria.INTEREST">
                  </span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="col-4" v-if="currentInfo">
              <div style="background: black; color: white; padding: 10px">
                <span style="font-weight: bold; font-size: 24px">{{ formatEstimate(currentInfo.subItem.audience) }}</span> Global audience size
              </div>

              <div style="padding: 10px">
                {{ currentInfo.item.name }} - {{ currentInfo.subItem.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {interestsCategories} from '@/util/data.js';
export default {
  props: ['formData', 'errors'],
  data() {
    return {
      form: {},
      interests: [...interestsCategories],
      currentInfo: null
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('x-interest-modal'));
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    formatEstimate(audienceSize) {
      const average = (audienceSize.max + audienceSize.min) / 2;
      let postfix = 'M';
      let value = average / 1000000;

      if (value < 1) {
        value = average / 1000;
        postfix = 'K';
      }
       return value.toFixed(1) + postfix;
    },
    loadSubCategories(category) {
      if (category.expanded) {
        category.expanded = false;
        return;
      }

      if (category.sub_categories) {
        category.expanded = true;
        return;
      }

      category.loading = true;

      this.axios.get('/twitter/targeting_criteria/interests', {
        params: {
          accountId: this.form.account,
          q: category.name
        }
      }).then((res) => {
        category.sub_categories = res.data.results[0].children;
        category.expanded = true;
      }).finally(() => {
        category.loading = false;

        this.$store.commit('twitterWizardModule/setInterests', [...this.interests]);
      })
    }
  }
}
</script>

<style scoped>
.main-list {
  padding-left: 0;
  list-style: none;
  font-weight: bold;
  font-size: 15px
}

.main-list-text {
  padding: 8px;
  display: block;
  border-radius: 3px;
  cursor: pointer;
}

.sub-list {
  list-style: none;
  font-weight: normal;
  padding-left: 0px
}

.sub-list-li {
  padding: 8px;
  cursor: pointer;
  border-radius: 3px;
}

.main-list-text:hover {
  background-color: lightgrey;
}

.sub-list-li span {
  padding-left: 18px;
}

.sub-list-li:hover {
  background-color: lightgrey;
}
</style>