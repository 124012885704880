<template>
  <div class="row">
    <div class="col-4">
      <product-preview v-if="!loadingProduct" />
      <div v-else>
        <div class="text-center">
          <div class="spinner-border" role="status"></div>
        </div>
        <p class="text-center">
          Loading product info.
          <br>This can take 10-20 seconds.
        </p>
      </div>
    </div>
    <div class="col-8">
      <div class="row mb-3">
        <div class="col-6">
          <amazon-profile-select ref="dashboardAdAccount"
                                 @changed="changeAccount"
                                 :amazonAdAccounts="amazonAdAccounts" />
        </div>
        <div class="col-6">
          <select class="form-control"
                  @change="changeTwitterAccount"
                  v-model="form.account">
            <option value="">Select X Account</option>
            <option v-for="(account, i) in accounts"
                    :value="account.id"
                    :key="'account_'+i">{{ account.name }}</option>
          </select>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12">
          <input type="text"
                 class="form-control"
                 v-model="form.amazon_attribution.campaign_name"
                 placeholder="Campaign Name">
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6">
          <select class="form-control"
                  :disabled="loadingProduct"
                  v-model="form.amazon_attribution.country_code">
            <option v-for="(marketplace, i) in amazonMarketplaces"
                    :value="marketplace.country_code"
                    :key="'marketplace_'+i">{{ marketplace.country }} ({{ marketplace.host }})</option>
          </select>
        </div>
        <div class="col-6">
          <div class="autocomplete" style="width:100%;">
            <input type="text"
                   :disabled="loadingProduct"
                   v-model="form.amazon_attribution.asin"
                   @keyup="getAmazonProduct($event)"
                   class="form-control"
                   placeholder="Enter 10 letters ASIN (e.g. B0S60VG188)">
          </div>
        </div>
      </div>
      <!--      <div class="row">
              <div class="col-12">
                <recent-asin @selectAsin="selectAsin" />
              </div>
            </div>-->
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import getSymbolFromCurrency from "currency-symbol-map";
import AmazonProfileSelect from "@/components/AmazonProfileSelect.vue";
// import RecentAsin from "@/views/create_campaign/twitter_wizard/step1/RecentAsin.vue";
import ProductPreview from "@/views/create_campaign/google_wizard/ProductPreview.vue";

export default {
  props: ['formData', 'errors', 'accounts'],
  components: {ProductPreview, AmazonProfileSelect},
  data() {
    return {
      form: {},
      loadingProduct: false,
      products: []
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters({
      amazonProduct: 'googleWizard/getAmazonProduct',
      amazonMarketplaces: 'adAccounts/getAmazonMarketplaces',
      amazonAdAccounts: 'adAccounts/getAmazonAccounts',
    }),
  },
  methods: {
    changeAccount(profile) {
      this.form.amazon_attribution.amazon_profile_id = profile;
    },
    changeTwitterAccount() {
      this.$emit('changeTwitterAccount')
    },
    getSymbolFromCurrency,
    selectAsin(asin) {
      this.form.amazon_attribution.asin = asin;
      this.getAmazonProduct();
    },
    getAmazonProduct() {
      if (this.form.amazon_attribution.asin.length === 10 &&
          (!this.amazonProduct || this.amazonProduct.asin !== this.form.amazon_attribution.asin.toUpperCase())) {

        this.loadingProduct = true;

        this.$store.dispatch('googleWizard/getAmazonProduct', {
          asin: this.form.amazon_attribution.asin,
          countryCode: this.form.amazon_attribution.country_code
        }).then((product) => {
          if (!product) {
            this.showFailMsg("This ASIN doesn't exist");
            return;
          }
          this.form.amazon_attribution.product_link = this.amazonProduct.url;
          this.form.amazon_attribution.asin = this.amazonProduct.asin;
          this.form.amazon_attribution.main_image = this.amazonProduct.main_image;
        }).catch((e) => {
          if(e.status === 500) {
            this.form.amazon_attribution.product_link = '';
            this.form.amazon_attribution.asin = '';
            this.form.amazon_attribution.main_image = '';
            this.showFailMsg("This ASIN doesn't exist");
          }
        }).finally(() => {
          this.loadingProduct = false;
        })
      }
    }
  }
}
</script>