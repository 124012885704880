<template>
  <div class="form-group">
    <label>Ad Text</label>
    <textarea class="form-control"
              v-model="adForm.creative.ad_text"
              rows="3"></textarea>
  </div>

  <select v-model="adForm.creative.reply">
    <option value="EVERYONE">Everyone can reply</option>
    <option value="FOLLOWING">People you follow</option>
    <option value="MENTIONED_USERS">Only people you mention</option>
  </select>
</template>
<script>
export default {
  props: ['ad'],
  data() {
    return {
      adForm: this.ad
    };
  },
  watch: {
    ad(newValue) {
      this.adForm = newValue;
    }
  }
};
</script>