<template>
  <div class="row">
    <div class="col-6">
      <AdsText :ad="form.ads[0]" />
    </div>
    <div class="col-6"
         v-if="form.objective.campaign_objective === 'WEBSITE_CLICKS'">
      <div class="form-group">
        <label>Card Title</label>
        <input class="form-control"
               v-model="form.card_title" />
      </div>
    </div>
  </div>
</template>

<script>
import AdsText from "@/views/create_campaign/twitter_wizard/components/AdsText.vue";
export default {
  props: ['formData', 'errors'],
  components: {AdsText},
  data() {
    return {
      form: {},
      products: []
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  }
}
</script>