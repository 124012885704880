<template>
  <div class="modal fade"
       id="modal-media-guideline"
       tabindex="3333"
       role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-add-cardLabel">Media guidelines</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div><!--end modal-header-->
        <div class="modal-body">
          <h4 class="text-black font-weight-bold">Videos</h4>
          <div>
            <p>Video ratio: 16:9 and 1:1.</p>
            <p>File size: 1GB Max</p>
            <p>File types: MP4 or MOV.</p>
            <p>Max time: 2 minutes and 20 seconds. (Select advertisers are eligible to request an increase up to 10 minutes. Please contact your Twitter Account Manager for more information.)</p>
            <p>Video codec recommendation: H264, Baseline, Main, or High profile with a 4:2:0 color space.</p>
            <p>Frame rate recommendation: 29.97FPS or 30FPS. Higher is acceptable. If the available video has a lower frame rate don’t try to “upsample” it.</p>
            <p>Video bitrate recommendation: 6,000 - 10,000k (recommended 6,000k) for 1080p. 5,000k - 8,000k (recommended 5,000k) for 720p).</p>
            <p>Audio codec recommendation: AAC LC (low complexity).</p>
          </div>

          <h4 class="text-black font-weight-bold mt-5">Images</h4>

          <div>
            <p>Photo size: 800 x 418 pixels for 1.91:1 aspect ratio, 800 x 800 pixels for 1:1 aspect ratio (max 3mb)</p>
            <p>Aspect ratio: 1.91:1 or 1:1.</p>
            <p>File types: PNG and JPEG are recommended. No BMP or TIFF files.</p>
          </div>
        </div><!--end modal-body-->
      </div><!--end modal-content-->
    </div><!--end modal-dialog-->
  </div><!--end modal-->
</template>

<script>
export default {
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-media-guideline'));
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
  }
}
</script>