<template>
  <div  class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <div class="row mb-3">
            <div class="col-8">
              <input class="form-control"
                     v-model="promptForm.prompt"
                     :disabled="fetchingEmail">
            </div>
            <div class="col-4 text-right">
              <button class="btn btn-info"
                      :disabled="fetchingEmail"
                      @click.prevent="fetchEmail">
                <template v-if="fetchingEmail">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Fetching...
                </template>
                <template v-else>
                  Fetch Email
                </template>
              </button>
            </div>
          </div>

          <editor :api-key="tinyMCEApiKey"
                  v-model="form.body_html"
                  output-format="html"/>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <select class="select2 select-lists"
                  v-show="showLists"
                  style="width: 100%"
                  multiple="multiple"
                  v-model="form.list_ids"
                  data-placeholder="Select List">
            <option :value="list.id"
                    v-for="list in lists"
                    :key="'list_'+list.id">{{ list.name }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {Editor},
  props: ['formData'],
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  data() {
    return {
      showLists: false,
      fetchingEmail: false,
      promptForm: {
        prompt: ''
      },
      form: {},
      prompts: [],
      lists: []
    }
  },
  mounted() {
    this.loadLists();
    // this.loadPrompts();
  },
  methods: {
    fetchEmail() {
      if (this.promptForm.prompt) {
        this.fetchingEmail = true;

        this.axios.post('/prompts/fetch-email', this.promptForm)
          .then(res => {
            this.form.body_html = res.data.data.replaceAll('\n', '<br>');
          })
          .finally(() => {
            this.fetchingEmail = false;
          })
      }
    },
    loadPrompts() {
      this.prompts = [];
      this.loading = true;

      this.axios.get('/prompts')
        .then(res => {
          this.prompts = res.data.data;
        })
        .finally(() => {
          this.loading = false;
        })
    },
    loadLists() {
      this.lists = [];

      this.axios.get('/email/lists')
        .then(res => {
          this.lists = res.data.data;
          this.initListsSelect2()
        })
    },
    initListsSelect2() {
      let self = this;

      window.$('.select-lists').select2();

      window.$('.select-lists').on('change', function () {
        self.form.list_ids = window.$(".select-lists").select2("val");
      });

      this.showLists = true;
    },
  }
}
</script>