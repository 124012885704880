<template>
  <div class="accordion" id="accordionStep3">
    <div class="card" style="border-bottom: 1px solid rgba(0,0,0,.125) !important;">
      <div class="card-header" id="accordionStep3heading">
        <p type="button"
           class="font-weight-bold mb-0"
           data-toggle="collapse"
           data-target="#collapseStep3Advance"
           aria-expanded="false"
           aria-controls="collapseOne">
          Advanced (if you’re not sure, don’t worry about it)
        </p>
      </div>

      <div id="collapseStep3Advance"
           class="collapse"
           aria-labelledby="accordionStep3heading"
           data-parent="#accordionStep3">
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="form-check">
                <input class="form-check-input"
                       type="radio"
                       v-model="form.settings.advance.bidding_strategy"
                       name="bid_cost"
                       id="bid_cost_1"
                       value="AUTO">
                <label class="form-check-label"
                       for="bid_cost_1">
                  Autobid
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input"
                       type="radio"
                       name="bid_cost"
                       id="bid_cost_2"
                       v-model="form.settings.advance.bidding_strategy"
                       value="TARGET">
                <label class="form-check-label"
                       for="bid_cost_2">
                  Target Cost
                </label>
              </div>

              <div class="mt-2"
                   v-if="form.settings.advance.bidding_strategy === 'TARGET'">
                <p>
                  Set a target cost to maintain an average cost per 1k impressions.
                </p>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">USD</span>
                  </div>
                  <input type="number"
                         v-model="form.settings.advance.target_cost"
                         class="form-control">
                  <div class="input-group-append">
                    <span class="input-group-text">average cost per 1k impressions</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h6 class="mt-3 font-weight-bold text-black">Frequency cap</h6>

          <div class="row">
            <div class="col-6">
              <div class="form-check">
                <input class="form-check-input"
                       type="radio"
                       name="frequency_cap"
                       id="frequency_cap_1"
                       v-model="form.settings.advance.frequency_cap"
                       value="1">
                <label class="form-check-label"
                       for="frequency_cap_1">
                  Automatically optimize ad frequency (recommended)
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input"
                       type="radio"
                       name="frequency_cap"
                       id="frequency_cap_2"
                       v-model="form.settings.advance.frequency_cap"
                       value="2">
                <label class="form-check-label"
                       for="frequency_cap_2">
                  Set a custom cap
                </label>
              </div>
            </div>
          </div>

          <div class="row mt-3"
               v-if="form.settings.advance.frequency_cap === '2'">
            <div class="col-3">
              <div class="input-group">
                <input type="number"
                       v-model="form.settings.advance.custom_cap.impression_per"
                       class="form-control">
                <div class="input-group-append">
                  <span class="input-group-text"
                        id="basic-addon2">impression per</span>
                </div>
              </div>
            </div>
            <div class="col-3">
              <select class="form-control"
                      v-model="form.settings.advance.custom_cap.day">
                <option value="1">1 Day</option>
                <option value="7">7 Days</option>
                <option value="30">30 Days</option>
              </select>
            </div>
          </div>

          <h6 class="font-weight-bold text-black mt-3">Pacing</h6>

          <div class="row">
            <div class="col-6">
              <div class="form-check">
                <input class="form-check-input"
                       type="radio"
                       name="pacing"
                       id="pacing_1"
                       v-model="form.settings.advance.pacing"
                       value="1">
                <label class="form-check-label"
                       for="pacing_1">
                  Standard (recommended)
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input"
                       type="radio"
                       name="pacing"
                       id="pacing_2"
                       v-model="form.settings.advance.pacing"
                       value="2">
                <label class="form-check-label"
                       for="pacing_2">
                  Accelerated
                </label>
                <p class="fs-10">Spend budget faster (good for time sensitive ads, like black friday)</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['formData', 'errors'],
  data() {
    return {
      form: {},
      products: []
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  }
}
</script>