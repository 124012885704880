<template>
  <div class="modal fade" id="campaign-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="campaign-modalLabel">Campaign</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
              <div class="mb-3">
                <label class="form-label">X Ad Account</label>
                <select class="form-select form-control"
                        id="google-campaign-select"
                        @change="loadTwitterCampaigns"
                        v-model="form.twitter_account_id">
                  <option value="">Select Account</option>
                  <option :value="account.id"
                          v-for="account in twitterAccounts"
                          :key="'twitter_'+account.id">{{ account.name }}</option>
                </select>
              </div>

              <div class="mb-3">
                <label class="form-label">X Campaign</label>
                <select class="form-select form-control"
                        id="google-campaign-select"
                        v-model="form.twitter_campaign_id">
                  <option value="">Select Campaign</option>
                  <option :value="campaign.id"
                          v-for="campaign in twitterCampaigns"
                          :key="'campaign_'+campaign.id">{{ campaign.name }}</option>
                </select>
              </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary btn-rounded my-2"
                  @click.prevent="submit">Save changes
          </button>
          <button type="button" class="btn btn-dark btn-rounded my-2" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    amazonCampaign: {
      type: Object,
      require: false
    }
  },
  watch: {
    amazonCampaign: {
      handler(campaign) {
        this.form.amazon_campaign = campaign ? campaign.name : '';
      },
      deep: true
    }
  },
  data() {
    return {
      currencies: [],
      googleCampaigns: [],
      fbCampaigns: [],
      twitterCampaigns: [],
      googleAdsLoading: false,
      form: {
        type: 'twitter',
        account_id: '',
        fb_account_id: '',
        twitter_account_id: '',
        amazon_campaign: '',
        fb_campaign: '',
        fb_campaign_name: '',
        twitter_campaign_id: '',
        twitter_campaign_name: '',
        google_campaign: '',
        google_campaign_name: '',
        isFixedAmount: false,
        currency: 'USD',
        fixedAmount: null,
        isPercentageAmount: false,
        percentageAmount: null,
        changeMassUrl: false,
        emails: []
      }
    }
  },
  created() {
    this.loadCurrencies();
  },
  mounted() {
    this.initInfluencerEmailsSelect2();
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings',
      twitterAccounts: 'adAccounts/getTwitterAccounts',
    }),
  },
  methods: {
    loadCurrencies() {
      this.axios.get('/currencies')
        .then(res => {
          this.currencies = res.data.data;
        })
    },
    initInfluencerEmailsSelect2() {
      let self = this;

      window.$('.influencer_emails').select2({
        tokenSeparators: ',',
        tags: true,
        dropdownParent: window.$('#campaign-modal')
      });

      window.$('.influencer_emails').on('change', function () {
        self.form.emails = window.$(".influencer_emails").select2("val");
      });
    },
    initSelect2(id, payload) {
      let self = this;

      window.$('#' + id).select2({
        dropdownParent: window.$('#campaign-modal')
      });

      window.$('#' + id).on('change', function () {
        self.form[payload] = window.$('#' + id).select2("val");
      });
    },
    changeMedia() {
      this.form.account_id = '';
      this.initInfluencerEmailsSelect2();

      /*if (this.form.type === 'fb') {
        this.accounts = this.fbAdAccounts;
      } else {
        this.accounts = this.googleAccounts;
      }*/
    },
    changeAccount(account) {
      if (this.form.type === 'fb') {
        this.changeFbAccount(account);
      } else {
        this.changeGoogleAccount(account);
      }
    },
    changeFbAccount(account) {
      this.fbCampaigns = [];
      this.form.fb_campaign = '';

      if (account) {
        this.loadCampaigns(account)
      }
    },
    changeGoogleAccount(profile) {
      this.googleCampaigns = [];
      this.form.google_campaign = '';

      if (profile) {
        this.googleAdsLoading = true;

        this.axios.get('/google/campaigns', {
          params: {
            id: profile
          }
        }).then(response => {
          this.googleCampaigns = response.data.data;

          setTimeout(() => {
            this.initSelect2('google-campaign-select', 'google_campaign')
          }, 500);
        }).finally(() => {
          this.googleAdsLoading = false;
        })
      }
    },
    loadCampaigns(act) {
      this.loading = true;

      this.axios.get('facebook/campaigns', {
          params: {
            act: act
          }
        })
        .then(response => {
          this.fbCampaigns = response.data.data;

          setTimeout(() => {
            this.initSelect2('fb-campaign-select', 'fb_campaign')
          }, 500);
        })
        .finally(() => {
          this.loading = false;
        })
    },
    loadTwitterCampaigns() {
      this.form.twitter_campaign_id = '';
      this.form.twitter_campaign_name = '';

      if (this.form.twitter_account_id !== '') {
        this.axios.get(`/twitter/campaigns/${this.form.twitter_account_id}`)
          .then(response => {
            this.twitterCampaigns = response.data.data;
          })
      }
    },
    submit() {
      this.loading = true;

      this.form.fb_campaign_name = '';
      this.form.twitter_campaign_name = '';
      this.form.google_campaign_name = '';
      let fbCampaign = null;
      let googleCampaign = null;
      let twitterCampaign = null;

      if (this.form.type === 'fb') {
        fbCampaign = this.fbCampaigns.find(c => c.id == this.form.fb_campaign);
        if (fbCampaign) {
          this.form.fb_campaign_name = fbCampaign.name;
        } else {
          alert('Select Facebook Account/Campaign');
          return true;
        }
      } else if (this.form.type === 'google') {
        googleCampaign = this.googleCampaigns.find(c => c.id == this.form.google_campaign);

        if (googleCampaign) {
          this.form.google_campaign_name = googleCampaign.name;
        } else {
          alert('Select Google Account/Campaign');
          return true;
        }
      } else if (this.form.type === 'twitter') {
        twitterCampaign = this.twitterCampaigns.find(c => c.id == this.form.twitter_campaign_id);

        if (twitterCampaign) {
          this.form.twitter_campaign_name = twitterCampaign.name;
        } else {
          alert('Select X Account/Campaign');
          return true;
        }
      }

      this.form.amazon_profile_id = this.settings.amazon_profile_id;

      this.axios.post('/set-campaign', this.form)
        .then(() => {
          this.$emit('campaignUpdate', this.form);
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>