<template>
  <div class="row justify-content-md-center">
    <div class="col-8">
      <div class="card">
        <div class="card-body text-center">
          <h6 class="card-title text-black">Just text with the product page’s preview</h6>
          <button @click.prevent="changeStep(11)"
                  :disabled="form.objective.campaign_objective !== 'REACH'"
                  class="btn btn-primary">Select</button>

          <p class="text-muted font-italic mt-3"
             v-if="form.objective.campaign_objective !== 'REACH'">Only "Reach" campaigns can be created using text only.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-md-center mt-2">
    <div class="col-8">
      <div class="card">
        <div class="card-body text-center">
          <h6 class="card-title text-black">Text with image/video link</h6>
          <a href="#"
             @click.prevent="showMediaUploader"
             class="btn btn-primary">Select</a>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-md-center mt-2">
    <div class="col-8">
      <div class="card">
        <div class="card-body text-center">
          <h6 class="card-title text-black">Create multiple ads in this ad group with different text/images</h6>
          <p>
            Good for when you want to try different approaches using different text/images, or for when you want to advertise each product variation separately
          </p>
          <a href="#"
             @click.prevent="changeStep(10)"
             class="btn btn-primary">Select</a>
        </div>
      </div>
    </div>
  </div>

  <TwitterMediaUploader ref="twitterMediaUploader"
                        @changeStep="changeStep" />
</template>

<script>
import TwitterMediaUploader from "@/views/create_campaign/twitter_wizard/step9/TwitterMediaUploader.vue";
export default {
  props: ['formData', 'errors'],
  components: {TwitterMediaUploader},
  data() {
    return {
      form: {},
      products: []
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  methods: {
    showMediaUploader() {
      let cleanOtherAds = false;

      if (this.form.ads.length > 1) {
        cleanOtherAds = true;
      }

      this.$refs.twitterMediaUploader.show(this.form.ads[0], true, cleanOtherAds);
    },
    changeStep(step) {
      if (step === 11) {
        this.form.ads = [this.form.ads[0]];
      }

      this.$emit('changeStep', step);
    }
  }
}
</script>