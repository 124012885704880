<template>
  <p>
    Narrow your audience based on their gender, age and location.
  </p>

  <h6 class="font-weight-bold text-black">Gender</h6>

  <div class="btn-group" >
    <button type="button"
            @click.prevent="changeGender('any')"
            :class="`btn ${form.audience.gender === 'any' ? 'btn-dark' : 'btn-outline-dark'}`">Any</button>
    <button type="button"
            @click.prevent="changeGender('women')"
            :class="`btn ${form.audience.gender === 'women' ? 'btn-dark' : 'btn-outline-dark'}`">Women</button>
    <button type="button"
            @click.prevent="changeGender('men')"
            :class="`btn ${form.audience.gender === 'men' ? 'btn-dark' : 'btn-outline-dark'}`">Men</button>
  </div>

  <h6 class="font-weight-bold text-black mt-3">Age</h6>
  <div class="form-check">
    <input class="form-check-input"
           type="radio"
           name="age"
           id="age_all"
           v-model="form.audience.age"
           value="all">
    <label class="form-check-label"
           for="age_all">
      All
    </label>
  </div>
  <div class="form-check">
    <input class="form-check-input"
           type="radio"
           name="age"
           id="age_range"
           v-model="form.audience.age"
           value="range">
    <label class="form-check-label"
           for="age_range">
      Age Range
    </label>
  </div>

  <div class="row mt-2" v-if="form.audience.age === 'range'">
    <div class="col-2">
      <select class="form-control form-control-sm"
              v-model="form.audience.age_range.from">
        <option value="13">13</option>
        <option value="18">18</option>
        <option value="21">21</option>
        <option value="25">25</option>
        <option value="35">35</option>
        <option value="50">50</option>
      </select>
    </div>
    <div class="col-2">
      <select class="form-control form-control-sm"
              v-model="form.audience.age_range.to">
        <option value="24"
                v-if="form.audience.age_range.from < 19">24</option>
        <option value="34"
                v-if="form.audience.age_range.from < 25">34</option>
        <option value="49"
                v-if="form.audience.age_range.from < 49">49</option>
        <option value="54"
                v-if="form.audience.age_range.from < 50">54</option>
        <option value="any">Any up</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: ['formData', 'errors'],
  data() {
    return {
      form: {},
      products: []
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  methods: {
    changeGender(gender) {
      this.form.audience.gender = gender;
    }
  }
}
</script>