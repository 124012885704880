<template>
  <h6 class="font-weight-bold text-black">Twitter placements</h6>
  <p>
    The more ad placements you select, the greater your potential reach.
  </p>

  <div class="form-check">
    <input class="form-check-input"
           type="checkbox"
           value="TWITTER_TIMELINE"
           id="placement_1"
           disabled checked>
    <label class="form-check-label"
           for="placement_1">
      Home timelines
    </label>
  </div>

  <div class="form-check">
    <input class="form-check-input"
           type="checkbox"
           value="TWITTER_PROFILE"
           v-model="form.placements"
           id="placement_2">
    <label class="form-check-label"
           for="placement_2">
      Profiles
    </label>
  </div>

  <div class="form-check">
    <input class="form-check-input"
           type="checkbox"
           v-model="form.placements"
           value="TWITTER_SEARCH"
           id="placement_3">
    <label class="form-check-label"
           for="placement_3">
      Search results
    </label>
  </div>

  <div class="form-check">
    <input class="form-check-input"
           type="checkbox"
           value="TWITTER_REPLIES"
           v-model="form.placements"
           id="placement_4">
    <label class="form-check-label"
           for="replies">
      Replies
    </label>
  </div>
</template>

<script>
export default {
  props: ['formData', 'errors'],
  data() {
    return {
      form: {},
      products: []
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  }
}
</script>