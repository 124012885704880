<template>
  <page-title-component title="Facebook Campaigns"></page-title-component>

  <div class="row mb-3">
    <div class="col-4">
      <select class="form-select"
              ref="adAccount"
              v-model="fbAccount"
              @change="changeAccount($event.target.value)">
        <option value="">Select Ad Account</option>

        <option :value="account.id"
                v-for="account in fbAdAccounts"
                :key="'ac_'+account.id">{{ account.name }}</option>
      </select>
    </div>
  </div>

  <div class="row" v-if="errorMsg">
    <div class="col-md-12">
      <div class="alert alert-danger border-0" role="alert">
        {{ errorMsg }}
      </div>
    </div>
  </div>

  <template v-else>
    <div class="row">
      <div class="col-12">
        <div class="text-center" v-if="loading">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <div class="table-responsive" v-else>
          <table class="table mb-0 table-centered">
            <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Spend</th>
              <!--            <th class="text-right">Action</th>-->
            </tr>
            </thead>
            <tbody>
            <tr v-for="(ad, i) in ads" :key="'ad_'+i">
              <td>{{ ad.id }}</td>
              <td>{{ ad.name }}</td>
              <td>
                <div>${{ ad.lifetime_budget }}</div>
              </td>
<!--              <td>
                <span :class="['badge', {'badge-outline-secondary': ad.status !== 'ACTIVE'}, {'badge-outline-success': ad.status === 'ACTIVE'}]">{{ ad.status }}</span>
              </td>-->
              <!--            <td class="text-right">
                            <div class="dropdown d-inline-block">
                              <a class="dropdown-toggle arrow-none" id="dLabel11" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                <i class="las la-ellipsis-v font-20 text-muted"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dLabel11">
                                <a class="dropdown-item"
                                   @click.prevent="showPreview(ad)"
                                   href="#">Preview</a>
                              </div>
                            </div>
                          </td>-->
            </tr>
            </tbody>
          </table><!--end /table-->
        </div>
      </div>
    </div>
  </template>

  <facebook-ads-preview-modal ref="fbAdsPreviewModal"></facebook-ads-preview-modal>
</template>
<script>
import PageTitleComponent from "@/components/PageTitleComponent";
import FacebookAdsPreviewModal from "@/components/FacebookAdsPreviewModal";
import {mapGetters} from "vuex";
export default {
  components: {FacebookAdsPreviewModal, PageTitleComponent},
  data() {
    return {
      ads: [],
      fbAccount: '',
      errorMsg: null,
      facebookAdsPreviewModal: null
    }
  },
  computed: {
    ...mapGetters({
      fbAdAccounts: 'adAccounts/getFbAccounts',
      settings: 'settings/getSettings'
    })
  },
  watch: {
    fbAdAccounts: {
      handler() {
        if (this.settings.facebook_profile_id) {
          this.fbAccount = this.settings.facebook_profile_id;
        }
      },
      deep: true
    },
    settings: {
      handler(val) {
        if (val.facebook_profile_id) {
          this.fbAccount = val.facebook_profile_id;
          this.loadCampaigns(val.facebook_profile_id)
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.settings.facebook_profile_id) {
      this.$refs.adAccount.value = this.settings.facebook_profile_id;
      this.loadCampaigns(this.settings.facebook_profile_id)
    }

    this.facebookAdsPreviewModal = new window.bootstrap.Modal(document.getElementById('modal-facebook-ads-preview'))
  },
  methods: {
    changeAccount(account) {
      this.ads = [];

      this.$store.dispatch('settings/saveSettings', {
        facebook_profile_id: account
      })

      if (account) {
        this.loadCampaigns(account)
      }
    },
    loadCampaigns(act) {
      this.loading = true;

      this.axios.get('facebook/campaigns', {
          params: {
            act: act
          }
        })
        .then(response => {
          this.ads = response.data.data;
          this.ads = this.ads.map(ad => {
            ad.lifetime_budget = ad.lifetime_budget ? (parseFloat(ad.lifetime_budget) / 100) : 0;

            return ad;
          })

          /*this.ads.forEach(ad => {
            // this.getLifetimeBudget(ad);
            // this.getPageName(ad);
          })*/
        })
        .catch(err => {
          this.errorMsg = err.response.data.message
        })
        .finally(() => {
          this.loading = false;
        })
    },
    showPreview(ad) {
      this.facebookAdsPreviewModal.show();
      this.$refs.fbAdsPreviewModal.loadAd(ad.creative.id)
    },
    getLifetimeBudget(ad) {
      this.axios.post('/fb-campaign-spend', {
          campaign_id: ad.id
        })
        .then(response => {
          ad.lifetime_budget = (response.data.data.length ? response.data.data[0].spend : '0');
        })
    },
    getPageName(ad) {
      this.axios.get('ads/facebook/page/' + ad.creative.id)
        .then(response => {
          ad.page_name = response.data.data.name;
        })
    }
  }
}
</script>