<template>
  <div v-if="amazonProduct" class="bk-box">
    <div class="row">
      <div class="col-12 text-center">
        <img :src="amazonProduct.main_image" style="max-width: 100%; height: 150px">
      </div>
    </div>

    <p class="mt-3">{{ amazonProduct.title }}</p>

    <div class="row">
      <div class="col-12 text-center my-3 mt-4">
        <button class="btn btn-success"
                @click="showPopup">
          <img :src="baseUrl + 'images/amazon_logo.png'" class="mr-1" style="height: 15px">
          Open Product Details
        </button>
      </div>
    </div>
  </div>

  <product-modal ref="productModal" />
</template>

<script>
import {mapGetters} from "vuex";
import ProductModal from "@/views/create_campaign/google_wizard/ProductModal";

export default {
  components: {ProductModal},
  computed: {
    ...mapGetters({
      amazonProduct: 'googleWizard/getAmazonProduct'
    })
  },
  methods: {
    showPopup() {
      this.$refs.productModal.show();
    }
  }
}
</script>


<style scoped>

.bk-box{
  max-width: 85%;
  margin: 35px auto 0px;
  padding: 15px;
  box-shadow: 0px 1px 3px #dddddd70;
  border-radius: 3px;
}

@media screen and (max-width: 767px) {
  .bk-box{
    margin-bottom: 30px;
  }
}

</style>