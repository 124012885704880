<template>
  <select class="form-select form-control"
          id="amazon-profile-select"
          ref="linkAdAccount"
          :disabled="disabled"
          @change="changeAccount($event.target.value)">
    <option value="">Select Ad Account</option>

    <optgroup label="North America" v-if="amazonAdAccounts.filter(a => a.region === 'NA').length">
      <option :value="account.profileId"
              v-for="account in amazonAdAccounts.filter(a => a.region === 'NA')"
              :key="'ac_'+account.profileId">
        {{ account.accountInfo.name }}
      </option>
    </optgroup>

    <optgroup label="Europe" v-if="amazonAdAccounts.filter(a => a.region === 'EU').length">
      <option :value="account.profileId"
              v-for="account in amazonAdAccounts.filter(a => a.region === 'EU')"
              :key="'ac_'+account.profileId">
        {{ account.accountInfo.name }}
      </option>
    </optgroup>

    <optgroup label="Far East" v-if="amazonAdAccounts.filter(a => a.region === 'FE').length">
      <option :value="account.profileId"
              v-for="account in amazonAdAccounts.filter(a => a.region === 'FE')"
              :key="'ac_'+account.profileId">
        {{ account.accountInfo.name }}
      </option>
    </optgroup>
  </select>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      profileId: ''
    }
  },
  props: {
    amazonAdAccounts: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      account: 'auth/getAccount',
      settings: 'settings/getSettings',
      currentAmazonProfileId: 'getCurrentAmazonProfileId'
    })
  },
  watch: {
    amazonAdAccounts() {
      setTimeout(() => {
        if (this.settings.amazon_profile_id) {
          this.setValue(this.settings.amazon_profile_id);
          this.changeAccount(this.settings.amazon_profile_id);
        }
      }, 100)
    },
    settings: {
      handler(val) {
        if (!this.profileId && val.amazon_profile_id) {
          setTimeout(() => {
            this.setValue(val.amazon_profile_id);
            this.changeAccount(val.amazon_profile_id);
          }, 500)
        }
      },
      deep: true
    }
  },
  mounted() {
    this.initSelect2();

    if (this.settings && this.settings.amazon_profile_id) {
      this.setValue(this.settings.amazon_profile_id);
      this.changeAccount(this.settings.amazon_profile_id, false);
    }
  },
  methods: {
    changeAccount(profileId, save = true) {
      if (this.currentAmazonProfileId === profileId) {
        return;
      }

      this.profileId = profileId;
      this.$store.commit('setCurrentAmazonProfileId', profileId);

      this.$emit('changed', profileId);

      if (!this.account || this.account.role !== 1)
        return;

      if (save) {
        this.$store.dispatch('settings/saveSettings', {
          amazon_profile_id: profileId
        });
      }
    },
    setValue(profileId) {
      window.$('#amazon-profile-select').val(profileId).trigger('change');
    },
    formatSelect2Item(profile) {
      if (!profile.id) {
        return profile.text;
      }

      let account = this.amazonAdAccounts.find(a => a.profileId == profile.id);
      let self = this;

      let item = window.$(
          '<span style="font-weight: bold"><img src="https://flagsapi.com/'+account.countryCode.replace('UK', 'GB')+'/flat/24.png" class="img-flag" /> ' + account.accountInfo.name + '<span style="font-weight: normal; color: grey"> - '+ self.capitalizeFirstLetter(account.accountInfo.type) +'</span><span style="float: right; font-weight: normal; color: grey">'+account.currencyCode+'</span></span><br>' +
          '<span style="color: grey; font-size: 12px">Account ID: '+account.accountInfo.id + ', '+ account.marketplace_domain+'</span>'
      );

      return item;
    },
    initSelect2() {
      let self = this;

      window.$('#amazon-profile-select').select2({
        theme: 'bootstrap-5',
        templateResult: self.formatSelect2Item
      });

      window.$('#amazon-profile-select').on('select2:select', function (e) {
        let data = e.params.data;
        self.changeAccount(data.id)
      });
    },
  },
}
</script>