<template>
  <h5 class="text-black">Choose your campaign objective:</h5>
  <p>
    X will optimize performance for the objective you choose
  </p>

  <div class="row">
    <div class="col-4">
      <div class="card">
        <div class="card-body text-center">
          <h6 class="card-title text-black">Reach</h6>
          <p class="card-text">Maximize ad’s reach</p>
          <a href="#"
             @click.prevent="selectObjective('REACH')"
             class="btn btn-primary">Select</a>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card">
        <div class="card-body text-center">
          <h6 class="card-title text-black">Clicks</h6>
          <p class="card-text">Maximize link clicks</p>
          <a href="#"
              @click.prevent="selectObjective('WEBSITE_CLICKS')"
             class="btn btn-primary">Select</a>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="card">
        <div class="card-body text-center">
          <h6 class="card-title text-black">Keywords</h6>
          <p class="card-text">(coming soon)</p>
          <button disabled class="btn btn-primary">Select</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['formData', 'errors'],
  data() {
    return {
      form: {},
      products: []
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  methods: {
    selectObjective(objective) {
      this.form.objective.campaign_objective = objective;

      this.$emit('changeStep', 3);
    }
  }
}
</script>