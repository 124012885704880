<template>
  <table class="table table-bordered">
    <tr v-for="(ad, i) in form.ads"
        :key="'ad_'+i">
      <td width="10%">
        <button class="btn btn-sm btn-danger"
                v-if="form.ads.length > 1"
                @click.prevent="removeAd(i)">Remove</button>
      </td>
      <td width="10%">
        <carousel v-if="ad.creative.media.length > 1" autoplay="3000" :wrapAround="true">
          <template v-for="(media, j) in ad.creative.media"
                    :key="'media_'+j+'_'+i">
            <slide v-if="media.includes('.mp4')">
              <video class="img-fluid rounded" muted loop autoplay>
                <source :src="media" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </slide>
            <slide v-else>
              <img class="img-fluid rounded"
                   :style="{width: '100%'}"
                   :src="media" />
            </slide>
          </template>

          <template #addons>
            <pagination />
          </template>
        </carousel>

        <template v-else>
          <video class="img-fluid rounded"
                 v-if="ad.creative.media.length && ad.creative.media[0].includes('.mp4')"
                 muted loop autoplay>
            <source :src="ad.creative.media[0]" type="video/mp4">
            Your browser does not support the video tag.
          </video>

          <img class="img-fluid rounded"
               v-else
               :style="{width: '100%'}"
               :src="ad.creative.media.length ? ad.creative.media[0] : '/images/no-image1.png'" />
        </template>


        <a class="btn btn-sm btn-block btn-secondary mt-3"
           @click.prevent="$refs.twitterMediaUploader.show(ad)">Change</a>
      </td>
      <td>
        <AdsText :ad="ad" />
      </td>
    </tr>
  </table>

  <button class="btn btn-sm btn-info"
          @click.prevent="addNewAd">+ Add New</button>

  <TwitterMediaUploader ref="twitterMediaUploader" />
</template>

<script>
import AdsText from "@/views/create_campaign/twitter_wizard/components/AdsText.vue";
import TwitterMediaUploader from "@/views/create_campaign/twitter_wizard/step9/TwitterMediaUploader.vue";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination } from 'vue3-carousel'

export default {
  props: ['formData', 'errors'],
  components: {AdsText, TwitterMediaUploader, Carousel, Slide, Pagination},
  data() {
    return {
      form: {},
      products: []
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  methods: {
    addNewAd() {
      this.form.ads.push({
        creative: {
          ad_text: '',
          reply: 'EVERYONE',
          media: []
        }
      });
    },
    removeAd(i) {
      this.form.ads.splice(i, 1);
    }
  }
}
</script>