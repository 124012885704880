<template>
  <page-title-component title="Amazon Campaigns"></page-title-component>

  <div class="row">
    <div class="col-3 mb-3">
      <div class="input-group">
        <input type="text"  id="reportrange" class="form-control" value="10/24/1984">
        <span class="input-group-text"><i class="ti ti-calendar font-16"></i></span>
      </div>
    </div>

    <div class="col-3 mb-3">
      <select class="form-select"
              ref="adAccount"
              @change="changeAccount($event.target.value)">
        <option value="">Select Ad Account</option>

        <option :value="account.profileId"
                v-for="account in amazonAdAccounts"
                :key="'ac_'+account.profileId">{{ account.accountInfo.name }}</option>
      </select>
    </div>

    <hr>
  </div>

  <div class="row" v-if="errorMsg">
    <div class="col-md-12">
      <div class="alert alert-danger border-0" role="alert">
        {{ errorMsg }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="text-center" v-if="loading">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div class="table-responsive" v-else>
        <table class="table mb-0 table-centered">
          <thead>
          <tr>
            <th>Campaign Name</th>
            <th>Click-throughs</th>
            <th>Total DPV</th>
            <th>Total ATC</th>
            <th>Total Purchases</th>
            <th>Total Product Sales</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(campaign, i) in campaigns" :key="'campaign_'+i">
            <tr :class="{'table-info': activeCampaignIndex === i}">
              <td>
                <a href="#"
                   @click.prevent="changeCampaignIndex(i)">
                  <span v-if="activeCampaignIndex === i"><i class="fas fa-minus"></i></span>
                  <span v-else><i class="fas fa-plus"></i></span>
                  {{ campaign.name }}
                </a>
              </td>
              <td>{{ campaign.click_through }}</td>
              <td>{{ campaign.dpv }}</td>
              <td>{{ campaign.atc }}</td>
              <td>{{ campaign.total_purchase }}</td>
              <td>${{ campaign.total_sale }}</td>
            </tr>

            <tr :class="['table-active', {'d-none': activeCampaignIndex !== i}]">
              <td colspan="6" class="p-2">
                <table class="table mb-0 table-centered">
                  <thead>
                  <tr>
                    <th>Ad Group</th>
                    <th>Click-throughs</th>
                    <th>Total DPV</th>
                    <th>Total ATC</th>
                    <th>Total Purchases</th>
                    <th>Total Product Sales</th>
                    <th>FB Campaign</th>
                    <th>FB Spend</th>
                  </tr>
                  </thead>

                  <tbody>
                  <tr v-for="(adGroup, i) in campaign.ad_groups" :key="'ad_group_'+i">
                    <td>{{ adGroup.name }}</td>
                    <td>{{ adGroup.click_through }}</td>
                    <td>{{ adGroup.dpv }}</td>
                    <td>{{ adGroup.atc }}</td>
                    <td>{{ adGroup.total_purchase }}</td>
                    <td>${{ adGroup.total_sale }}</td>
                    <td>{{ getFacebookCampaignName(adGroup) }}</td>
                    <td>
                      <div class="spinner-border spinner-border-sm text-secondary"
                           v-if="!adGroup.spend"
                           role="status">
                        <span class="sr-only">Loading...</span>
                      </div>

                      <div v-else>{{ adGroup.spend }}</div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </template>
          </tbody>
        </table><!--end /table-->
      </div>
    </div>
  </div>

  <campaign-modal :fbCampaigns="facebookCampaigns"
                  @campaignUpdate="modalCampaignUpdate"
                  :amazonAdGroup="selectedAdGroup"></campaign-modal>
</template>
<script>
import PageTitleComponent from "@/components/PageTitleComponent";
import CampaignModal from "@/views/advertise/CampaignModal";
import {mapGetters} from "vuex";

export default {
  components: {PageTitleComponent, CampaignModal},
  data() {
    return {
      campaignModal: null,
      selectedAdGroup: null,
      activeCampaignIndex: null,
      facebookCampaigns: [],
      campaigns: [],
      errorMsg: null,
      queryParams: {
        profileId: '',
        startDate: window.moment().subtract(29, "days").format('YYYYMMDD'),
        endDate: window.moment().format('YYYYMMDD')
      }
    }
  },
  computed: {
    ...mapGetters({
      amazonAdAccounts: 'adAccounts/getAmazonAccounts',
      settings: 'settings/getSettings'
    })
  },
  watch: {
    settings: {
      handler(val) {
        if (val.amazon_profile_id) {
          this.$refs.adAccount.value = val.amazon_profile_id;
          this.queryParams.profileId = val.amazon_profile_id;
          this.loadData()
        }
      },
      deep: true
    }
  },
  created() {
    // this.loadData();
    this.loadFbCampaigns();
  },
  mounted() {
    if (this.settings.amazon_profile_id) {
      this.$refs.adAccount.value = this.settings.amazon_profile_id;
      this.queryParams.profileId = this.settings.amazon_profile_id;
      this.loadData()
    }

    let t = window.moment().subtract(29, "days");
    let a = window.moment();
    let self = this;

    this.campaignModal = new window.bootstrap.Modal(document.getElementById('campaign-modal'));

    window.$("#reportrange").daterangepicker({
        startDate: t,
        endDate: a,
        locale: {
          format: 'MMM D, YYYY'
        },
        ranges: {
          Today: [window.moment(), window.moment()],
          Yesterday: [window.moment().subtract(1, "days"), window.moment().subtract(1, "days")],
          "Last 7 Days": [window.moment().subtract(6, "days"), window.moment()],
          "Last 30 Days": [window.moment().subtract(29, "days"), window.moment()],
          "This Month": [window.moment().startOf("month"), window.moment().endOf("month")],
          "Last Month": [window.moment().subtract(1, "month").startOf("month"), window.moment().subtract(1, "month").endOf("month")],
        },
      },
      function (t, a) {
        self.queryParams.startDate = t.format('YYYYMMDD');
        self.queryParams.endDate = a.format('YYYYMMDD');
        self.loadData();
      }
    )
  },
  methods: {
    changeAccount(profile) {
      this.campaigns = [];
      this.queryParams.profileId = profile;

      this.$store.dispatch('settings/saveSettings', {
        amazon_profile_id: profile
      })

      if (profile) {
        this.loadData();
      }
    },
    modalCampaignUpdate(data) {
      this.campaignModal.hide();

      this.selectedAdGroup.fb_campaign_id = data.fb_campaign;

      this.selectedAdGroup.spend = '';

      this.getFbSpend(this.selectedAdGroup);
    },
    getFacebookCampaignName(adGroup) {
      if (adGroup.fb_campaign_id) {
        let fbCampaign = this.facebookCampaigns.find(c => c.id == adGroup.fb_campaign_id);

        if (fbCampaign)
          return fbCampaign.name;
        else
          return '';
      } else {
        return ''
      }
    },
    getFacebookCampaignCost(adGroup) {
      if (adGroup.fb_campaign_id) {
        let fbCampaign = this.facebookCampaigns.find(c => c.id == adGroup.fb_campaign_id);

        if (fbCampaign)
          return '$'+(parseFloat(fbCampaign.lifetime_budget) / 100).toFixed(2);
        else
          return '';
      } else {
        return ''
      }
    },
    changeCampaign(adGroup) {
      this.selectedAdGroup = adGroup;
      this.campaignModal.show();
    },
    changeCampaignIndex(index) {
      if (this.activeCampaignIndex === index)
        this.activeCampaignIndex = null;
      else {
        this.activeCampaignIndex = index

        this.campaigns[this.activeCampaignIndex].ad_groups.forEach(adGroup => {
          this.getFbSpend(adGroup);
        })
      }
    },
    loadFbCampaigns() {
      this.axios.get('/facebook/campaigns')
        .then(response => {
          this.facebookCampaigns = response.data.data;
        })
    },
    loadData() {
      this.loading = true;
      this.campaigns = [];
      this.errorMsg = null;

      this.axios.get('ads/amazon/campaign', {
          params: this.queryParams
        })
        .then(response => {
          this.campaigns = response.data.data
        })
        .catch(err => {
          this.errorMsg = err.response.data.message
        })
        .finally(() => this.loading = false)
    },
    getFbSpend(adGroup) {
      let fbCampaign = this.facebookCampaigns.find(c => c.id == adGroup.fb_campaign_id);

      if (fbCampaign) {
        this.axios.post('/fb-campaign-spend', {
          campaign_id: fbCampaign.id,
          startDate: this.queryParams.startDate,
          endDate: this.queryParams.endDate,
        }).then(response => {
          if (response.data.data.length) {
            if (response.data.data[0].spend) {
              adGroup.spend = '$' + response.data.data[0].spend;
            } else {
              adGroup.spend = '$0';
            }
          }
        })
      } else {
        adGroup.spend = '$0';
      }
    }
  }
}
</script>