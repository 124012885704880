<template>
  <page-title-component title="Create X Campaign" />

  <div class="row">
    <div class="col-lg-12 layout-spacing">
      <div class="statbox widget box box-shadow">
        <div class="widget-content widget-content-area"
             id="twitter-wizard-template">
          <div class="underline-content">
            <ul class="nav nav-tabs mb-3 justify-content-between" role="tablist">
              <li class="nav-item">
                <a :class="['nav-link nav-item btn-rounded', {'active': currentStep === 1}]"
                   href="javascript:void(0)"
                   role="tab"
                   aria-selected="false">
                  <i class="fa-brands fa-amazon"></i> Amazon Attribution
                </a>
              </li>
              <li class="nav-item">
                <a :class="['nav-link nav-item btn-rounded', {'active': currentStep === 2}]"
                   href="javascript:void(0)"
                   role="tab"
                   aria-selected="false">
                  <i class="fa-solid fa-circle-info"></i> Objective
                </a>
              </li>
              <li class="nav-item">
                <a :class="['nav-link nav-item btn-rounded', {'active': currentStep === 3}]"
                   href="javascript:void(0)"
                   role="tab"
                   aria-selected="false">
                  <i class="fa-solid fa-gear"></i> Settings
                </a>
              </li>
              <li class="nav-item">
                <a :class="['nav-link nav-item btn-rounded', {'active': currentStep === 4}]"
                   href="javascript:void(0)"
                   role="tab"
                   aria-selected="false">
                  <i class="fa fa-arrows"></i> Placements
                </a>
              </li>
              <li class="nav-item">
                <a :class="['nav-link nav-item btn-rounded', {'active': currentStep === 5}]"
                   href="javascript:void(0)"
                   role="tab"
                   aria-selected="false">
                  <i class="fa-solid fa-users"></i> Audiences
                </a>
              </li>
              <li class="nav-item">
                <a :class="['nav-link nav-item btn-rounded', {'active': currentStep === 6}]"
                   href="javascript:void(0)"
                   role="tab"
                   aria-selected="false">
                  <i class="fa-solid fa-mobile"></i> Devices
                </a>
              </li>
              <li class="nav-item">
                <a :class="['nav-link nav-item btn-rounded', {'active': currentStep === 7}]"
                   href="javascript:void(0)"
                   role="tab"
                   aria-selected="false">
                  <i class="fa-solid fa-briefcase"></i> Interests
                </a>
              </li>
              <li class="nav-item">
                <a :class="['nav-link nav-item btn-rounded', {'active': currentStep === 8}]"
                   href="javascript:void(0)"
                   role="tab"
                   aria-selected="false">
                  <i class="fa-solid fa-lightbulb"></i> Creatives
                </a>
              </li>
              <li class="nav-item">
                <a :class="['nav-link nav-item btn-rounded', {'active': [9, 10].includes(currentStep)}]"
                   href="javascript:void(0)"
                   role="tab"
                   aria-selected="false">
                  <i class="fa-solid fa-images"></i> Media
                </a>
              </li>
              <li class="nav-item">
                <a :class="['nav-link nav-item btn-rounded', {'active': currentStep === 11}]"
                   href="javascript:void(0)"
                   role="tab"
                   aria-selected="false">
                  <i class="fa-solid fa-check"></i> Review
                </a>
              </li>
            </ul>
          </div>
          <div class="row">
            <div class="col-12">
              <component :is="'Step' + currentStep"
                         :formData="form"
                         :twitterProfile="twitter.profile"
                         :accounts="twitter.accounts"
                         :platforms="twitter.platforms"
                         :fundingInstruments="twitter.fundingInstruments"
                         @changeTwitterAccount="changeTwitterAccount"
                         @changeStep="changeStepTo"></component>
            </div>
          </div>

          <hr>

          <div class="row">
            <div class="col-6">
            </div>
            <div class="col-6 text-right">
              <button class="btn btn-outline-default mr-4"
                      @click="changeStepTo(currentStep === 11 ? 9 : (currentStep - 1))"
                      v-if="currentStep !== 1"
                      :disabled="currentStep === 1 || loading">Previous</button>

              <button class="btn btn-primary"
                      v-if="currentStep < 11"
                      :disabled="!canGoNext"
                      @click="currentStep++">Next</button>

              <button class="btn btn-primary"
                      v-else
                      :disabled="loading"
                      @click="createCampaign">Launch Ad</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageTitleComponent from "@/components/PageTitleComponent.vue";
import Step1 from "@/views/create_campaign/twitter_wizard/steps/Step1.vue";
import Step2 from "@/views/create_campaign/twitter_wizard/steps/Step2.vue";
import Step3 from "@/views/create_campaign/twitter_wizard/steps/Step3.vue";
import Step4 from "@/views/create_campaign/twitter_wizard/steps/Step4.vue";
import Step5 from "@/views/create_campaign/twitter_wizard/steps/Step5.vue";
import Step6 from "@/views/create_campaign/twitter_wizard/steps/Step6.vue";
import Step7 from "@/views/create_campaign/twitter_wizard/steps/Step7.vue";
import Step8 from "@/views/create_campaign/twitter_wizard/steps/Step8.vue";
import Step9 from "@/views/create_campaign/twitter_wizard/steps/Step9.vue";
import Step10 from "@/views/create_campaign/twitter_wizard/steps/Step10.vue";
import Step11 from "@/views/create_campaign/twitter_wizard/steps/Step11.vue";
import useVuelidate from "@vuelidate/core";
import {minValue, numeric, required, requiredIf} from "@vuelidate/validators";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      currentStep: 1,
      loading: false,
      twitter: {
        accounts: [],
        profile: null,
        fundingInstruments: [],
        platforms: []
      },
      form: {
        account: '',
        card_title: '',
        status: 'DRAFT',
        amazon_attribution: {
          asin: '',
          main_image: '',
          country_code: 'US',
          product_link: '',
          campaign_name: '',
          amazon_profile_id: ''
        },
        objective: {
          campaign_objective: ''
        },
        settings: {
          funding_source: '',
          start_date: window.moment().startOf("hour").format('YYYY-MM-DD'),
          end_date: window. moment().startOf("hour").add(1, "day").format('YYYY-MM-DD'),
          daily_budget: '',
          total_budget: '',
          advance: {
            bidding_strategy: 'AUTO',
            target_cost: '',
            frequency_cap: '1',
            custom_cap: {
              impression_per: '',
              day: ''
            },
            pacing: '1'
          }
        },
        placements: ['TWITTER_TIMELINE', 'TWITTER_PROFILE', 'TWITTER_SEARCH', 'TWITTER_REPLIES'],
        audience: {
          gender: 'any',
          age: 'all',
          age_range: {
            from: '13',
            to: 'any'
          },
          keywords: [],
          targeting_criteria: {
            INTEREST: [],
            LOCATION: [],
            CONVERSATION: []
          }
        },
        devices: [],
        ads: [
          {
            creative: {
              ad_text: '',
              reply: 'EVERYONE',
              media: []
            }
          }
        ]
      }
    }
  },
  mounted() {
    if (this.settings && this.settings.amazon_profile_id) {
      this.form.amazon_attribution.amazon_profile_id = this.settings.amazon_profile_id;
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  validations() {
    if (this.currentStep === 1) {
      return {
        form: {
          account: {required},
          amazon_attribution: {
            asin: {required},
            // product_link: {required},
            campaign_name: {required},
            amazon_profile_id: {required},
          },
        },
      }
    } else if (this.currentStep === 3) {
      return {
        form: {
          settings: {
            funding_source: {required},
            start_date: {required},
            end_date: {required},
            daily_budget: {required, numeric, minValue: minValue(0.01)},
            total_budget: {numeric},
          },
        },
      }
    } else if (this.currentStep === 8) {
      return {
        form: {
          card_title: {
            required: requiredIf(function(){
              return this.form.objective.campaign_objective === 'WEBSITE_CLICKS';
            })
          },
          ads: [{
            creative: {
              ad_text: {required}
            }
          }]
        },
      }
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings'
    }),
    canGoNext() {
      if (this.currentStep === 1) {
        // return true;
        return !this.v$.form.$invalid
      } else if (this.currentStep === 2) {
        return false;
      } else if (this.currentStep === 3) {
        return !this.v$.form.$invalid
      } else if (this.currentStep === 8) {
        return !this.v$.form.$invalid
      } else if (this.currentStep === 9) {
        return false;
      }

      return true;
    }
  },
  components: {
    PageTitleComponent, Step1, Step2, Step3, Step4, Step5, Step6, Step7, Step8, Step9, Step10, Step11
  },
  created() {
    this.loadTwitterAccounts();
    this.loadPlatforms();
    this.loadTwitterProfile();
  },
  methods: {
    changeTwitterAccount() {
      if (this.form.account !== '') {
        this.loadTwitterFundingInstruments();
      } else {
        this.twitter.fundingInstruments = [];
      }
    },
    loadTwitterAccounts() {
      this.axios.get('/twitter/accounts')
          .then((response) => {
            this.twitter.accounts = response.data.data;
          }).catch(() => {});
    },
    loadTwitterFundingInstruments() {
      this.axios.get(`/twitter/accounts/${this.form.account}/funding_instruments`)
          .then((response) => {
            this.twitter.fundingInstruments = response.data.data;
          }).catch(() => {});
    },
    loadPlatforms() {
      this.axios.get('/twitter/targeting_criteria/platforms')
          .then((response) => {
            this.twitter.platforms = response.data.data;
            this.form.devices = this.twitter.platforms.map(platform => platform.targeting_value);
          }).catch(() => {});
    },
    loadTwitterProfile() {
      this.axios.get('/twitter/profile')
          .then(res => {
            this.twitter.profile = res.data.data;
          });
    },
    createCampaign() {
      this.loading = true;
      let form = {...this.form};
      // form.audience.targeting_criteria.INTEREST = form.audience.targeting_criteria.INTEREST.map(id => ({
      //   id: id
      // }));

      this.axios.post(`/twitter/accounts/${this.form.account}/campaigns`, form)
          .then(() => {
            this.$store.commit('dashboardModule/setForceLoad', true);
            this.showSuccessMsg("Campaign created");
            this.$store.commit('googleWizard/setAmazonProduct', null);
            this.$router.push({name: 'dashboard'});
          })
          .catch((err) => {
            this.loading = false;

            this.$swal.fire({
              icon: "error",
              title: "Error",
              text: err.response.data.message
            })
            // this.showFailMsg(err.response.data.message);
          })
          .finally(() => {
            this.loading = false;
          })
    },
    changeStepTo(step) {
      this.currentStep = step;
    }
  }
}
</script>