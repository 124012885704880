<template>
  <div class="row">
    <div class="col-md-8 m-auto">
      <page-title-component title="Details"></page-title-component>
      <div>
        <div  class="card">
          <div class="card-body py-5">
            <div class="statbox widget box p-0" v-if="campaign">
              <div class="widget-content widget-content-area p-0 box-shadow-none">
                <div class="table-responsive">
                  <table class="table table-bordered mb-0" >
                    <tbody>
                    <tr>
                      <th>Campaign Name</th>
                      <td>{{ campaign.amazon_campaign }}</td>
                    </tr>
                    <tr>
                      <th>ASIN</th>
                      <td>{{ campaign.influencer_asin }}</td>
                    </tr>
                    <tr>
                      <th>Spend</th>
                      <td>
                        <span v-if="campaign.influencer_amount">Amount: {{ campaign.influencer_amount }}</span><br>
                        <span v-if="campaign.influencer_percentage">Percentage: {{ campaign.influencer_percentage }} %</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Link</th>
                      <td>
                        {{ campaign.influencer_full_link }}
                      </td>
                    </tr>
                    <tr v-if="campaign.influencer_short_link">
                      <th>Short Link</th>
                      <td>{{ campaign.influencer_short_link }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <router-link to="/" class="btn btn-primary mt-3 py-2 px-4 float-right">Go to Dashboad</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent";
export default {
  components: { PageTitleComponent },
  data() {
    return {
      campaign: null,
    }
  },

  created() {
    this.axios.get('/influencer/ad-details', {
      params: {
        campaignId: this.$route.params.id
      }
    }).then(res => this.campaign = res.data);
  }
}
</script>