<template>
  <page-title-component title="Report"></page-title-component>

  <div class="row mb-3">
    <div class="col-3 mb-3">
      <select class="form-select" @change="changeAccount($event.target.value)">
        <option value="">Select Ad Account</option>

        <option :value="account.profileId"
                v-for="account in amazonAdAccounts"
                :key="'ac_'+account.profileId">{{ account.accountInfo.name }}</option>
      </select>
    </div>

    <div class="col-md-3">
      <div class="text-center" v-if="loadingAmazonCampaigns">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <select class="form-select"
              v-else
              v-model="currentCampaignId"
              @change="changeCampaign">
        <option value="">Select Campaign</option>

        <option :value="campaign.name"
                v-for="campaign in campaigns"
                :key="'campaign_'+campaign.id">{{ campaign.name }}</option>
      </select>
    </div>
  </div>

  <div class="row" v-show="showChart">
    <div class="col-lg-12 col-xl-12">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Last 6 Month</h4>
        </div><!--end card-header-->
        <div class="card-body">
          <canvas id="bar" class="drop-shadow" height="40" width="100%"></canvas>
        </div><!--end card-body-->
      </div><!--end card-->
    </div> <!-- end col -->
  </div>

  <div class="row" v-if="currentCampaignId">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table mb-0 table-centered">
          <thead>
          <tr>
            <th>Campaign Name</th>
            <th>Click-throughs</th>
            <th>Total DPV</th>
            <th>Total ATC</th>
            <th>Total Purchases</th>
            <th>Total Product Sales</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(campaign, i) in campaigns.filter(c => c.name === currentCampaignId)" :key="'campaign_'+i">
            <tr :class="{'table-info': activeCampaignIndex === i}">
              <td>
                <a href="#"
                   @click.prevent="changeCampaignIndex(i)">
                  <span v-if="activeCampaignIndex === i"><i class="fas fa-minus"></i></span>
                  <span v-else><i class="fas fa-plus"></i></span>
                  {{ campaign.name }}
                </a>
              </td>
              <td>{{ campaign.click_through }}</td>
              <td>{{ campaign.dpv }}</td>
              <td>{{ campaign.atc }}</td>
              <td>{{ campaign.total_purchase }}</td>
              <td>${{ campaign.total_sale }}</td>
            </tr>

            <tr :class="['table-active', {'d-none': activeCampaignIndex !== i}]">
              <td colspan="6" class="p-2">
                <table class="table mb-0 table-centered">
                  <thead>
                  <tr>
                    <th>Ad Group</th>
                    <th>Click-throughs</th>
                    <th>Total DPV</th>
                    <th>Total ATC</th>
                    <th>Total Purchases</th>
                    <th>Total Product Sales</th>
                    <th>FB Campaign</th>
                    <th>FB Spend</th>
                  </tr>
                  </thead>

                  <tbody>
                  <tr v-for="(adGroup, i) in campaign.ad_groups" :key="'ad_group_'+i">
                    <td>{{ adGroup.name }}</td>
                    <td>{{ adGroup.click_through }}</td>
                    <td>{{ adGroup.dpv }}</td>
                    <td>{{ adGroup.atc }}</td>
                    <td>{{ adGroup.total_purchase }}</td>
                    <td>${{ adGroup.total_sale }}</td>
                    <td>{{ adGroup.fb_campaign_name }}</td>
                    <td>
                      <div class="spinner-border spinner-border-sm text-secondary"
                           v-if="!adGroup.spend"
                           role="status">
                        <span class="sr-only">Loading...</span>
                      </div>

                      <div v-else>{{ adGroup.spend }}</div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </template>
          </tbody>
        </table><!--end /table-->
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent";
import {mapGetters} from "vuex";

export default {
  components: {PageTitleComponent},
  data() {
    return {
      loadingAmazonCampaigns: false,
      campaigns: [],
      labels: [],
      activeCampaignIndex: null,
      facebookCampaigns: [],
      showChart: false,
      currentCampaignId: '',
      queryParams: {
        profileId: '',
        startDate: window.moment().subtract(6, "months").format('YYYYMMDD'),
        endDate: window.moment().format('YYYYMMDD')
      }
    }
  },
  computed: {
    ...mapGetters({
      amazonAdAccounts: 'adAccounts/getAmazonAccounts'
    })
  },
  created() {
    // this.loadFbCampaigns();
  },
  methods: {
    changeAccount(profile) {
      this.campaigns = [];
      this.queryParams.profileId = profile;

      if (profile) {
        this.loadData();
      }
    },
    loadData() {
      this.loadingAmazonCampaigns = true;
      this.campaigns = [];

      this.axios.get('/reports/spend', {
          params: this.queryParams
        })
        .then((response) => {
          this.campaigns = response.data.data.campaigns
          this.labels = response.data.data.months
        })
        .finally(() => {
          this.loadingAmazonCampaigns = false;
        })
    },
    async changeCampaign() {
      this.showChart = false;

      if (this.currentCampaignId) {
        let campaign = this.campaigns.find(c => c.name === this.currentCampaignId);

        for (const adGroup of campaign.ad_groups) {
          // let fbCampaign = this.facebookCampaigns.find(c => c.id == adGroup.fb_campaign_id);

          if (adGroup.fb_campaign_id) {
            await this.axios.post('/fb-campaign-spend', {
              campaign_id: adGroup.fb_campaign_id,
            }).then(response => {
              if (response.data.data && response.data.data.length) {
                if (response.data.data[0].spend) {
                  adGroup.spend = '$' + response.data.data[0].spend;
                  campaign.fb_spend += parseFloat(response.data.data[0].spend);
                } else {
                  adGroup.spend = '$0';
                }
              }
            })
          } else {
            adGroup.spend = '$0';
          }
        }

        this.initChart(campaign);
      }
    },
    loadFbCampaigns() {
      this.axios.get('/facebook/campaigns')
        .then(response => {
          this.facebookCampaigns = response.data.data;
        })
    },
    changeCampaignIndex(index) {
      if (this.activeCampaignIndex === index)
        this.activeCampaignIndex = null;
      else {
        this.activeCampaignIndex = index

        /*this.campaigns.find(c => c.name === this.currentCampaignId).ad_groups.forEach(adGroup => {
          this.getFbSpend(adGroup);
        })*/
      }
    },
    getFacebookCampaignName(adGroup) {
      if (adGroup.fb_campaign_id) {
        let fbCampaign = this.facebookCampaigns.find(c => c.id == adGroup.fb_campaign_id);

        if (fbCampaign)
          return fbCampaign.name;
        else
          return '';
      } else {
        return ''
      }
    },
    getFbSpend(adGroup) {
      // let fbCampaign = this.facebookCampaigns.find(c => c.id == adGroup.fb_campaign_id);

      if (adGroup.fb_campaign_id) {
        this.axios.post('/fb-campaign-spend', {
          campaign_id: adGroup.fb_campaign_id,
          startDate: this.queryParams.startDate,
          endDate: this.queryParams.endDate,
        }).then(response => {
          if (response.data.data.length) {
            if (response.data.data[0].spend) {
              adGroup.spend = '$' + response.data.data[0].spend;
            } else {
              adGroup.spend = '$0';
            }
          }
        })
      } else {
        adGroup.spend = '$0';
      }
    },
    initChart(campaign) {
      let self = this;

      const ctx = document.getElementById('bar').getContext('2d');
      new window.Chart(ctx, {
        type: 'bar',
        data: {
          labels: self.labels,
          datasets: [
            {
              label: "FB Spend",
              backgroundColor: "rgba(23, 97, 253, 0.4)",
              borderColor: "#4a8af6",
              borderWidth: 2,
              barPercentage: 0.3,
              categoryPercentage: 0.5,
              hoverBackgroundColor: "rgba(23, 97, 253, 0.7)",
              hoverBorderColor: "#4a8af6",
              data: [campaign.fb_spend, campaign.fb_spend, campaign.fb_spend, campaign.fb_spend, campaign.fb_spend, campaign.fb_spend],
            },
            {
              label: "Amazon Sales",
              backgroundColor: "rgba(253,188,23,0.4)",
              borderColor: "#e3980e",
              borderWidth: 2,
              barPercentage: 0.3,
              categoryPercentage: 0.5,
              hoverBackgroundColor: "rgba(253,199,23,0.7)",
              hoverBorderColor: "#f6c54a",
              data: campaign.months,
            },
          ],
        },
        options: {
          responsive: !0,
          legend: {labels: {fontColor: "#8997bd"}},
          scales: {
            xAxes: [{
              barPercentage: 0.8,
              categoryPercentage: 0.4,
              display: !0,
              gridLines: {color: "rgba(137, 151, 189, 0.15)"},
              ticks: {fontColor: "#8997bd"}
            }],
            yAxes: [{gridLines: {color: "rgba(137, 151, 189, 0.15)"}, ticks: {fontColor: "#8997bd"}}],
          },
        }
      });

      this.showChart = true;
    }
  }
}
</script>