<template>
  <div class="row">
    <div class="col-6">
      <h6 class="font-weight-bold text-black">Keywords (optional)</h6>
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <select class="form-control form-control-sm"
                    v-model="keyword.type">
              <option value="Include">Include</option>
              <option value="Exclude">Exclude</option>
            </select>
          </div>
        </div>
        <input type="text"
               @keyup.enter="insertKeyword"
               v-model="keyword.text"
               placeholder="Enter keyword phrase and press enter"
               class="form-control" />
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <template v-for="(type, i) in keywordTypes"
              :key="'keyword_type_'+i">
      <div class="col-6"
           v-if="form.audience.keywords.filter(k => k.type === type).length">
        <p :class="`font-weight-bold ${type === 'Include' ? 'text-success' : 'text-danger'}`">{{ type }}</p>

        <div class="badge badge-dark mr-2 mb-2"
             v-for="(keyword, i) in form.audience.keywords.filter(k => k.type === type)"
             :key="'key_'+i">
          {{ keyword.text }}
          <a href="#"
             @click.prevent="deleteKeyword(keyword.text)"
             class="text-danger">X</a>
        </div>
      </div>
    </template>
  </div>

  <div class="row">
    <div class="col-12">
      <TargetingCriteria
          targetingType="LOCATION"
          :targetingCriteria="form.audience.targeting_criteria"
          type="locations"
          title="Locations" />
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      <h6 class="font-weight-bold text-black">
        Interests
        <button class="btn btn-secondary btn-sm" @click.prevent="$refs.interestModal.show()">Select Interest</button>
      </h6>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <div class="badge badge-dark mr-2 mb-2"
           v-for="(id, i) in form.audience.targeting_criteria.INTEREST"
           :key="'interest_'+i">
        {{ getKeywordText(id) }}
        <a href="#"
           @click.prevent="deleteInterest(id)"
           class="text-danger">X</a>
      </div>
    </div>
  </div>

  <InterestModal ref="interestModal"
                 :formData="form" />
</template>

<script>
import InterestModal from "@/views/create_campaign/twitter_wizard/step7/InterestModal.vue";
import TargetingCriteria from "@/views/create_campaign/twitter_wizard/step7/TargetingCriteria.vue";
export default {
  props: ['formData', 'errors'],
  components: {InterestModal, TargetingCriteria},
  data() {
    return {
      form: {},
      keywordTypes: ['Include', 'Exclude'],
      keyword: {
        type: 'Include',
        text: ''
      }
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },
  },
  methods: {
    insertKeyword() {
      const exits = this.form.audience.keywords.find(k => k.text === this.keyword.text);

      if (this.keyword.text.length && !exits) {
        this.form.audience.keywords.push({...this.keyword});
      }

      this.keyword.text = '';
    },
    deleteKeyword(text) {
      this.form.audience.keywords = this.form.audience.keywords.filter(k => k.text !== text);
    },
    deleteInterest(id) {
      this.form.audience.targeting_criteria.INTEREST = this.form.audience.targeting_criteria.INTEREST.filter(i => i !== id);
    },
    getKeywordText(id) {
      const interest = this.$store.state.twitterWizardModule.interests.find(i =>
          i.sub_categories && i.sub_categories.some(sub => sub.id == id)
      );
      if (interest) {
        const subCategory = interest.sub_categories.find(sub => sub.id === id);
        return `${interest.name} - ${subCategory.text}`;
      }
      return '';
    }
  }
}
</script>