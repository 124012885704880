<template>
  <div class="modal fade"
       id="modal-facebook-ads-preview"
       tabindex="-1"
       role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title m-0" id="exampleModalDefaultLabel">Preview</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div><!--end modal-header-->
        <div class="modal-body text-center">
          <div v-html="iframe" v-if="iframe"></div>
        </div><!--end modal-body-->
      </div><!--end modal-content-->
    </div><!--end modal-dialog-->
  </div><!--end modal-->
</template>

<script>
export default {
  data() {
    return {
      iframe: null,
    }
  },
  methods: {
    loadAd(adCreativeId) {
      this.iframe = null;

      this.axios.get('ads/facebook/preview/' + adCreativeId)
        .then(response => {
          this.iframe = response.data.data[0].body;
        })
    }
  }
}
</script>