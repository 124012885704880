<template>
  <page-title-component title="Facebook Advertise Details"></page-title-component>

  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col">
              <h4 class="card-title">Preview</h4>
            </div><!--end col-->
            <div class="col-auto">
              <a :href="'https://www.facebook.com/adsmanager/manage/ads/edit?act='+$route.params.act.replace('act_', '')+'&selected_ad_ids=' + $route.params.id"
                 target="_blank"
                 class="btn btn-primary">
                <i class="fas fa-edit"></i> Edit on Facebook
              </a>
            </div><!--end col-->
          </div>  <!--end row-->
        </div>
        <div class="card-body">
          <div v-html="iframe" v-if="iframe"></div>
        </div><!--end card-body-->
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent";

export default {
  components: {PageTitleComponent},
  data() {
    return {
      iframe: null,
    }
  },
  created() {
    this.loadAd()
  },
  methods: {
    loadAd() {
      this.iframe = null;

      this.axios.get('ads/facebook/preview/' + this.$route.params.id)
        .then(response => {
          this.iframe = response.data.data[0].body;
        })
    }
  }
}
</script>